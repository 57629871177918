body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .p-content {
    @apply text-white6  text-xs sm:text-sm leading-normal;
  }

  .p-content-noBottom {
    @apply p-content mb-0;
  }

  .p-content-my4 {
    @apply p-content my-4;
  }

  .title-responsive-font-size {
    @apply text-2xl sm:text-3xl md:text-4xl text-white;
  }

  .title-responsive-font-size-mb5 {
    @apply title-responsive-font-size mb-5;
  }

  .title-responsive-font-size-mb0 {
    @apply title-responsive-font-size mb-0;
  }

  .titler-esponsive-font-size-mb2 {
    @apply title-responsive-font-size mb-2;
  }

  .industryV-common {
    @apply relative p-20 md:px-24 md:pr-28 flex items-center justify-center;
  }

  .industryV-common2 {
    @apply mt-8 sm:mt-0 text-center sm:text-left;
  }

  .footer-common {
    @apply sm:inline-block lg:mb-0 text-center;
  }

  .home-common {
    @apply pl-20 sm:pl-28 absolute z-10;
  }

  .home-border {
    @apply border-2 border-solid rounded-full cursor-pointer;
  }

  .home-border-right {
    @apply home-border mx-8;
  }

  .common-center {
    @apply absolute left-1/2 transform -translate-x-1/2  text-center text-white w-full;
  }

  .common-hover-scale-110 {
    @apply hover:transform hover:scale-110 transition duration-500 ease-in-out;
  }

  .common-hover-scale-125 {
    @apply hover:transform hover:scale-125 transition duration-500 ease-in-out;
  }
}
